/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hourglass-bottom': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-1v1a4.5 4.5 0 01-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0112.5 13v1h1a.5.5 0 010 1h-11a.5.5 0 110-1h1v-1a4.5 4.5 0 012.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 013.5 3V2h-1a.5.5 0 01-.5-.5m2.5.5v1a3.5 3.5 0 001.989 3.158c.533.256 1.011.791 1.011 1.491v.702s.18.149.5.149.5-.15.5-.15v-.7c0-.701.478-1.236 1.011-1.492A3.5 3.5 0 0011.5 3V2z"/>',
    },
});
